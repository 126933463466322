* {
  margin: 0;
  padding: 0;
}
.component-nav-container .login-list {
  position: fixed;
  top: 12vh;
  right: 30px;
  z-index: 2;
}
.component-nav-container .login-list ul {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
}
.component-nav-container .login-list ul li {
  width: 80px;
  text-align: center;
  padding: 10px 0;
  border-bottom: 0.5px solid #C1C1C1;
}
.component-nav-container .login-list ul li a {
  color: #FFFFFF;
  font-size: 14px;
}
.component-nav-container .login-list ul li:last-child {
  border: none;
}
.component-nav-container .head-bar {
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  opacity: 1;
  transition: opacity 2s;
  background-color: #000000;
  box-shadow: 0 0 5px 0 rgba(210, 210, 210, 0.77);
}
.component-nav-container .head-bar .container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}
.component-nav-container .head-bar .container > img {
  width: 122px;
}
.component-nav-container .head-bar .container > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
}
.component-nav-container .head-bar .container > ul > li {
  min-width: 65px;
  margin-left: 50px;
  text-align: center;
}
.component-nav-container .head-bar .container > ul > li > a {
  font-size: 16px;
  color: #FFFFFF;
  height: 100%;
  line-height: 100px;
}
.component-nav-container .head-bar .container > ul > li > a:hover .applyop-ul {
  visibility: visible;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul {
  display: flex;
  position: absolute;
  width: 980px;
  top: 100px;
  left: 50%;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(210, 210, 210, 0.77);
  transform: translateX(-50%);
  line-height: 1;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul .title {
  margin: 24px 46px;
  text-align: left;
  font-size: 30px;
  border-right: 1px solid #D8D8D8;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul .sub-nav {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul .sub-nav > div {
  text-align: left;
  flex: 1;
  cursor: pointer;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul .sub-nav > div:hover {
  color: #26C441;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul .pop-bg {
  width: 300px;
}
.component-nav-container .head-bar .container > ul > li .applyop-ul > div {
  flex: 1;
  color: #000000;
}
.component-nav-container .head-bar .container > ul > li .head-apply-active {
  opacity: 1;
  height: auto;
}
.component-nav-container .head-bar .container > ul > .green {
  color: #26C441;
}
.component-nav-container .head-bar .container > ul > .container-li > a:focus {
  color: #26C441;
}
.component-nav-container .head-bar .container > ul .active > a {
  color: #23BC39 !important;
}
.component-nav-container .head-bar .container > ul > #applyop > a:hover {
  color: #26C441;
}
.component-nav-container .head-active > a {
  color: #26C441 !important;
}
.component-nav-container .user-info {
  position: absolute;
  right: 4px;
  top: 52px;
  padding: 6px;
  background: hsla(0, 0%, 100%, 0.9);
  color: #555555;
  font-size: 13px;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  display: none;
}
.component-nav-container .head-apply-active {
  opacity: 1;
  height: auto;
}
@media (min-width: 1300px) {
  .component-nav-container .container {
    width: 1200px !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .component-nav-container .container {
    width: 770px !important;
  }
}
@media (max-width: 767px) {
  .component-nav-container .user-info {
    right: 0;
    top: 44px;
    border-radius: 0 0 0 4px;
    padding: 4px;
    font-size: 12px;
  }
  .component-nav-container .login-list {
    right: 14px;
  }
  .component-nav-container .login-list ul li {
    width: 66px;
    padding: 8px 0;
  }
  .component-nav-container .login-list ul li a {
    font-size: 13px;
  }
  .component-nav-container .container > ul > .container-li > a:focus {
    color: #26C441;
  }
  .component-nav-container .container > ul .active > a {
    color: #23BC39 !important;
  }
}
.component-nav-container .container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (max-width: 767px) {
  .component-nav-container body {
    padding-top: 44px;
  }
  .component-nav-container .head-bar-xs {
    position: fixed;
    left: 0;
    right: 0;
    height: 44px;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 10001;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(210, 210, 210, 0.77);
  }
  .component-nav-container .head-bar-xs > img:nth-child(1) {
    width: 100px;
    height: auto;
  }
  .component-nav-container .head-bar-xs > img:nth-child(2) {
    width: 18px;
    height: auto;
  }
  .component-nav-container .head-bar-xs > img:nth-child(2).bar-active {
    transform: rotate(90deg);
  }
  .component-nav-container .head-bar-xs .side-bar {
    display: none;
    position: fixed;
    right: 0;
    left: 0;
    top: 42px;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    flex-direction: row;
    justify-content: flex-end;
  }
  .component-nav-container .head-bar-xs .side-bar.bar-active {
    display: flex;
  }
  .component-nav-container .head-bar-xs .side-bar .bar-list {
    padding: 20px 0 20px 15px;
    width: 60%;
    background-color: #FFFFFF;
    z-index: 9999;
  }
  .component-nav-container .head-bar-xs .side-bar .bar-list > ul > li {
    min-height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #DCDCDC;
  }
  .component-nav-container .head-bar-xs .side-bar .bar-list > ul > li > ul > li {
    padding-left: 15px;
  }
  .component-nav-container .head-bar-xs .side-bar .bar-list > ul > .container-li > a:focus {
    color: #23BC39;
  }
  .component-nav-container .head-bar-xs .side-bar .bar-list > ul .active > a {
    color: #23BC39 !important;
  }
}
