.components-footer-container {
  position: relative;
  bottom: 0;
  width: 100%;
}
.components-footer-container .foot-bar {
  height: 423px;
}
.components-footer-container .foot-bar .foot-bar-container {
  display: flex;
  justify-content: space-between;
  padding: 57px 55px 72px 100px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-logo {
  width: 189px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-qrcode {
  padding: 40px 0 40px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-qrcode > ul {
  display: flex;
  flex-direction: row;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-qrcode > ul > li {
  margin-right: 30px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-qrcode > ul > li > img {
  width: 110px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-qrcode > ul > li > p {
  padding-top: 4px;
  text-align: center;
  font-size: 12px;
  color: #FFFFFF;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-email {
  min-width: 500px;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-left .foot-bar-left-corporation {
  letter-spacing: 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link {
  margin-left: -195px;
  text-align: center;
  padding-top: 90px;
  display: flex;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link .footer-right-ul {
  margin-right: 27px;
  display: flex;
  flex-direction: column;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link .footer-right-ul:last-child {
  margin-right: 0;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link .footer-right-ul .footer-right-ul-title {
  padding-bottom: 16px;
  color: #FFFFFF;
  font-size: 16px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link .footer-right-ul .footer-right-ul-title > a {
  color: #FFFFFF;
  font-size: 16px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link .footer-right-ul .footer-right-ul-content {
  padding-bottom: 8px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right-link .footer-right-ul .footer-right-ul-content > a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right {
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  margin-left: -100px;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right .foot-bar-right-cs {
  color: #FFFFFF;
  font-size: 22px;
  text-align: left;
}
.components-footer-container .foot-bar .foot-bar-container .foot-bar-right .foot-bar-right-cs .foot-bar-right-time {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  padding-top: 8px;
  text-align: center;
}
.components-footer-container .foot-bar-xs {
  background-color: none;
}
.components-footer-container .foot-bar-xs .foot-bar-container {
  flex-direction: column;
  padding: 29px 0;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left {
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-left-logo {
  width: 90px;
  margin: 0 auto;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-left-qrcode {
  padding: 45px 20px 20px;
  justify-content: center;
  border-bottom: 1px solid #626262;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-left-qrcode img {
  width: 60px;
  margin: 0 auto;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-left-qrcode p {
  text-align: center;
  margin-top: 10px;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-phone {
  border-bottom: 1px solid #626262;
  padding: 20px 0;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-phone .foot-bar-email {
  font-size: 9px;
  color: #A3A2A2;
  letter-spacing: 0;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-phone .foot-bar-phone-emails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  font-size: 14px;
  line-height: 20px;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left .foot-bar-phone .foot-bar-phone-emails .line {
  width: 17px;
  height: 1px;
  background: #626262;
  margin: 30px 0 25px;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-right-cs {
  color: #FFFFFF;
  font-size: 15px;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-right-time {
  padding-top: 4px;
  font-size: 9px;
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left-address {
  padding-top: 8px;
  color: rgba(255, 255, 255, 0.45);
}
.components-footer-container .foot-bar-xs .foot-bar-container .foot-bar-left-corporation {
  font-size: 9px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 15px;
  padding-top: 4px;
}
.footer-container {
  color: #FFFFFF;
  height: 500px;
  background-color: #000000;
}
.footer-container .top-container {
  height: 360px;
  border-bottom: 1px solid #373737;
  border-top: 1px solid #373737;
  display: flex;
}
.footer-container .top-container .left {
  width: 60%;
  border-right: 1px solid #373737;
}
.footer-container .top-container .left .left-top-container {
  height: 200px;
  margin-top: 36px;
}
.footer-container .top-container .left .left-top-container .icon > img {
  width: 100px;
  float: right;
}
.footer-container .top-container .left .left-top-container .item {
  padding-left: 135px;
}
.footer-container .top-container .left .left-top-container .item .title {
  display: inline-block;
  font-size: 18px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 20px;
  cursor: pointer;
}
.footer-container .top-container .left .left-top-container .item .sub-title {
  font-size: 14px;
  margin-bottom: 10px;
  color: #D4D4D4;
  cursor: pointer;
}
.footer-container .top-container .left .left-top-container .item .sub-title > span {
  display: inline-block;
  width: 10px;
  border-bottom: 1px solid #D8D8D8;
  vertical-align: middle;
  margin-right: 10px;
}
.footer-container .top-container .left .left-top-container .item > div {
  margin-right: 100px;
}
.footer-container .top-container .left .left-bottom-container {
  border-top: 1px solid #373737;
  height: 124px;
  padding: 0 50px 0;
}
.footer-container .top-container .left .left-bottom-container > p {
  font-size: 14px;
  color: #FFFFFF;
}
.footer-container .top-container .left .left-bottom-container .time {
  font-size: 12px;
  color: #898989;
}
.footer-container .top-container .left .left-bottom-container .phone {
  margin: 8px 0;
}
.footer-container .top-container .right {
  width: 40%;
}
.footer-container .top-container .right .qrcode-container {
  display: flex;
  margin-top: 36px;
  margin-left: 100px;
}
.footer-container .top-container .right .qrcode-container .weibo:hover .weibo-img {
  visibility: visible;
}
.footer-container .top-container .right .qrcode-container .alipay:hover .alipay-img {
  visibility: visible;
}
.footer-container .top-container .right .qrcode-container .wx:hover .wx-img {
  visibility: visible;
}
.footer-container .top-container .right .qrcode-container .icon {
  cursor: pointer;
  margin: 0 10px;
  width: 30px;
}
.footer-container .top-container .right .qrcode-container .qrcode {
  visibility: hidden;
  position: absolute;
  left: -40px;
  background-color: #FFFFFF;
  padding: 6px;
  top: 45px;
}
.footer-container .top-container .right .qrcode-container .qrcode img {
  width: 110px;
}
.footer-container .top-container .right .qrcode-container .qrcode::before {
  content: '';
  width: 0;
  height: 0;
  border: 16px solid;
  position: absolute;
  top: -30px;
  left: 45px;
  border-color: transparent transparent #FFFFFF;
}
.footer-container .top-container .right .qrcode-container > div {
  position: relative;
}
.footer-container .top-container .right .emails-contrl {
  margin-top: 191px;
  margin-left: 50px;
  font-size: 14px;
  line-height: 20px;
}
.footer-container .bottom-container {
  height: 140px;
}
.footer-container .bottom-container .corporation {
  font-size: 12px;
  color: #898989;
  height: 100%;
  line-height: 140px;
  text-indent: 400px;
}
