.scroll-content {
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
  height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll-content::-webkit-scrollbar {
  display: none;
}
.scroll-content > .content {
  background-image: url('https://img3.dian.so/lhc/2022/05/18/3840w_14584h_03C2F1652866893.png');
  background-size: cover;
  background-color: #000000;
  min-height: 100vh;
}
.scroll-content .wrapper {
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .fixed-box {
    display: none;
  }
  .scroll-content .wrapper {
    background-color: #000000;
  }
  .scroll-content > .content {
    height: 100% !important;
    background-image: none;
  }
}
@media (min-width: 767px) {
  .back-top {
    display: none;
  }
}
.back-top {
  cursor: pointer;
  width: 10vw;
  position: fixed;
  right: 6%;
  bottom: 5%;
  z-index: 1000;
}
.fixed-box {
  cursor: pointer;
  position: fixed;
  right: 6%;
  bottom: 5%;
  z-index: 1000;
}
.fixed-box img {
  width: 5vw;
  min-width: 70px;
}
